<template>
  <div class="w-full">
    <div class="md:flex mb-4">
      <div class="w-full md:w-9/12">
        <div class="font-extrabold text-black text-lg my-4">
          Booking No. {{ $props.dataTrx.nobukti }}
        </div>
        <div class="w-full md:px-4">
          <div class="flex flex-row gap-4">
            <div
              class="w-1/5 rounded-xl h-28"
              :style="{
                backgroundImage: 'url(\'' + fotoPaket + '\')',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'contain',
              }"
            ></div>
            <div class="w-3/5">
              <div class="grid grid-cols-1 font-bold">
                <span class="text-primary text-xl">{{
                  $props.dataTrx.namapaket
                }}</span>
                <span class="text-black text-md">{{
                  $props.dataTrx.namatoko
                }}</span>
                <span class="text-black text-sm">{{
                  $props.dataTrx.alamattoko
                }}</span>
              </div>
            </div>
            <div class="w-1/5">
              <div
                class="grid grid-cols-1 content-end items-end text-primary h-full cursor-pointer"
                @click="redirectNewTab"
              >
                <span class="font-bold"
                  ><font-awesome-icon
                    icon="fa-solid fa-location-dot"
                    class="mt-3"
                  />
                  Meeting Point</span
                >
              </div>
            </div>
          </div>
          <div class="border-2 border-grey-1 my-4"></div>
          <div class="md:grid md:grid-cols-2 gap-10">
            <div
              class="my-1 flex flex-col gap-2 font-semibold md:font-bold text-sm md:text-base"
            >
              <div class="flex w-full">
                <div class="w-1/2">
                  <span class="">Nama</span>
                </div>
                <div class="w-1/2 flex">
                  <span class="mr-1">:</span>
                  <span class="">{{ $store.state.user.nama }}</span>
                </div>
              </div>
              <div class="flex w-full">
                <div class="w-1/2">
                  <span class="">Starting Date</span>
                </div>
                <div class="w-1/2 flex">
                  <span class="mr-1">:</span>
                  <span class="">
                    {{
                      $moment($props.dataTrx.tgl).format("dddd, DD MMMM YYYY")
                    }}
                  </span>
                </div>
              </div>
              <div class="flex w-full">
                <div class="w-1/2">
                  <span class="">Starting Time</span>
                </div>
                <div class="w-1/2 flex">
                  <span class="mr-1">:</span>
                  <span class="">
                    {{ $props.dataTrx.jam_awal | reformatHour }}
                  </span>
                </div>
              </div>
              <div class="flex w-full">
                <div class="w-1/2">
                  <span class="">Trip Duration</span>
                </div>
                <div class="w-1/2 flex">
                  <span class="mr-1">:</span>
                  <span class="">
                    {{ $props.dataTrx.durasi }}
                  </span>
                </div>
              </div>
              <div class="flex w-full">
                <div class="w-1/2">
                  <span class="">Meeting Point</span>
                </div>
                <div class="w-1/2 flex">
                  <span class="mr-1">:</span>
                  <span class="">
                    {{ $props.dataTrx.dermaga }}
                  </span>
                </div>
              </div>
              <div class="flex w-full">
                <div class="w-1/2">
                  <span class="">Booked Unit</span>
                </div>
                <div class="w-1/2 flex">
                  <span class="mr-1">:</span>
                  <span class="">
                    {{ $props.dataTrx.unit }}
                  </span>
                </div>
              </div>
            </div>
            <div
              class="my-1 flex flex-col gap-2 font-semibold md:font-bold text-sm md:text-base"
            >
              <div class="flex w-full">
                <div class="w-1/2">
                  <span class="">Payment Date</span>
                </div>
                <div class="w-1/2 flex">
                  <span class="mr-1">:</span>
                  <span class="">
                    {{
                      $moment($props.dataTrx.waktu_bayar).format(
                        "dddd, DD MMMM YYYY"
                      )
                    }}
                  </span>
                </div>
              </div>
              <div class="flex w-full">
                <div class="w-1/2">
                  <span class="">Payment Time</span>
                </div>
                <div class="w-1/2 flex">
                  <span class="mr-1">:</span>
                  <span class="">
                    {{ $moment($props.dataTrx.waktu_bayar).format("HH:mm:ss") }}
                  </span>
                </div>
              </div>
              <div class="flex w-full">
                <div class="w-1/2">
                  <span class="">Payment Method</span>
                </div>
                <div class="w-1/2 flex">
                  <span class="mr-1">:</span>
                  <span class="">
                    {{ paymentMode }}
                  </span>
                </div>
              </div>
              <div class="flex w-full">
                <div class="w-1/2">
                  <span class="">Subtotal</span>
                </div>
                <div class="w-1/2 flex">
                  <span class="mr-1">:</span>
                  <span class="">
                    {{ $props.dataTrx.jumlah | toCurrency }}
                  </span>
                </div>
              </div>
              <div class="flex w-full">
                <div class="w-1/2">
                  <span class="">Total Payment</span>
                </div>
                <div class="w-1/2 flex">
                  <span class="mr-1">:</span>
                  <span class="">
                    <span
                      class="font-bold text-black text-md"
                    >
                      {{ jumlahakhir | toCurrency }}</span
                    >
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="border border-primary rounded-2xl mt-4 md:mt-0">
            <div
              class="flex flex-col gap-2 p-4 font-semibold md:font-bold text-sm md:text-base"
            >
              <div
                class="flex flex-row justify-between"
                v-for="(psn, idxpsn) in $props.dataTrx.daftarpesanan"
                :key="idxpsn"
              >
                <span class="text-black"
                  >{{ psn.namapaket }} {{ psn.qtyunit }}x
                  {{ psn.harga | toCurrency }}/hour</span
                >
                <span class="text-black">{{ psn.jumlah | toCurrency }}</span>
              </div>
              <div
                class="flex flex-row justify-between"
                v-if="$props.dataTrx.diskon > 0"
              >
                <span class="text-black">Voucher</span>
                <span class="text-black">{{
                  $props.dataTrx.diskon | toCurrency
                }}</span>
              </div>
              <div class="flex flex-row justify-between">
                <span class="text-black" v-if="!isCreditCard && !isTwPay"
                  >Unique Code</span
                >
                <span class="text-black" v-if="isCreditCard && !isTwPay"
                  >Credit Card admin fee (3.5%)</span
                >
                <span class="text-black text-right" v-if="!isTwPay">{{
                  $props.dataTrx.unik | toCurrency
                }}</span>
              </div>
            </div>
            <div
              class="px-4 py-2 bg-primary text-white rounded-br-2xl rounded-bl-2xl"
            >
              <div class="flex flex-row justify-between">
                <span class="">Total Payment</span>
                <span class="">{{
                  jumlahakhir | toCurrency
                }}</span>
              </div>
            </div>
          </div>
          <div class="flex justify-end">
            <button
              type="button"
              class="bg-yellow text-black font-extrabold py-2 px-4 mt-4 rounded-md font-xl"
              @click="handleBookAgain"
            >
              BOOK AGAIN
            </button>
          </div>
        </div>
      </div>
      <div class="md:w-3/12 h-12"></div>
    </div>
  </div>
</template>

<script>
import { Slugify } from '@/utils';
export default {
  name: "TransactionFinished",
  data() {
    return {
      iconUnit: require("@/assets/icons/jetski_icon.svg"),
    };
  },
  props: ["dataTrx"],
  computed: {
    timeExp() {
      const now = new Date();
      const newYear = this.$moment(this.$props.dataTrx.waktu_exp);
      return newYear - now;
    },
    fotoPaket() {
      var txt = this.$props.dataTrx.foto;
      // if (this.$props.dataTrx.tipe == "RENTAL") {
      //   txt =
      //     this.$props.dataTrx.daftarpesanan[0]?.foto ??
      //     "https://tripwe.id/ic/jetski-android-new/upload_logo/tw_sq.png";
      // }
      return txt;
    },
    isBankTransfer() {
      var txt = "";
      if (
        this.$props.dataTrx.tipe_reff == "BCA" ||
        this.$props.dataTrx.tipe_reff == "BRI" ||
        this.$props.dataTrx.tipe_reff == "PMT" ||
        ((this.$props.dataTrx.tipe_reff == null ||
          this.$props.dataTrx.tipe_reff == "") &&
          this.$props.dataTrx.reff.slice(0, 2) != "CC" &&
          this.$props.dataTrx.reff.slice(0, 3) != "C2C")
      ) {
        return true;
      }
      return false;
    },
    isTwPay() {
      var txt = "";
      if (
        this.$props.dataTrx.tipe_reff == "TWPAY" ||
        ((this.$props.dataTrx.tipe_reff == null ||
          this.$props.dataTrx.tipe_reff == "") &&
          this.$props.dataTrx.reff.slice(0, 3) == "C2C")
      ) {
        return true;
      }
      return false;
    },
    isCreditCard() {
      if (
        this.$props.dataTrx.tipe_reff == "CC" ||
        ((this.$props.dataTrx.tipe_reff == null ||
          this.$props.dataTrx.tipe_reff == "") &&
          this.$props.dataTrx.reff.slice(0, 2) == "CC")
      ) {
        return true;
      }
      return false;
    },
    jumlahakhir() {
      if (this.isTwPay) {
        return this.$props.dataTrx.jml_dskn;
      } else if (!this.isTwPay) {
        return (
          parseFloat(this.$props.dataTrx.jml_dskn) +
          parseFloat(this.$props.dataTrx.unik)
        );
      } else {
        return this.$props.dataTrx.jumlahakhir;
      }
    },
    paymentMode() {
      var str = "Bank Transfer";
      if (this.isBankTransfer) {
        str = "Bank Transfer";
      } else if (this.isTwPay) {
        str = "Balance";
      } else if (this.isCreditCard) {
        str = "Credit Card";
      }
      return str;
    },
  },
  mounted() {
  },
  methods: {
    transform(props) {
      Object.entries(props).forEach(([key, value]) => {
        // Adds leading zero
        const digits = value < 10 ? `0${value}` : value;

        // uses singular form when the value is less than 2
        const word = value < 2 ? key.replace(/s$/, "") : key;

        props[key] = `${digits}`;
      });

      return props;
    },
    async handleBookAgain() {
      if (this.$route.params.trip == "rental") {
        this.$router.push({
          name: 'Rental',
          params: { id_wilayah: this.dataTrx.id_wilayah, id_toko: this.dataTrx.toko }
        });
      }else{
        try {
          this.$store.commit("setOverlayLoading", true);
          const response = await this.$http.post(`${this.$apiTripweWeb}/package/detail/all`, {
            id_paket: this.dataTrx.id_paket
          });
          if(response.data.status != 200){
            throw response.data.message;
          }
          this.$router.push({
            name: 'PackageDetail',
            params: { slug: Slugify(response.data.data.nama), pack:response.data.data }
          });
        } catch (error) {
          this.$toasted.global.error(error);
        } finally {
          this.$store.commit("setOverlayLoading", false);
        }
      }
    },
    redirectNewTab() {
      var url =
        "https://maps.google.com/?q=" +
        this.$props.dataTrx.latitude +
        "," +
        this.$props.dataTrx.longitude;
      window.open(url, "_blank").focus();
    },
  },
};
</script>
