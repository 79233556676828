<template>
  <div class="w-full">
    <div class="md:grid gap-8 md:grid-cols-2">
      <div>
        <div class="w-full">
          <div class="font-extrabold text-black text-lg my-4">Booking No. {{ $props.dataTrx.nobukti }}</div>
          <div class="w-full md:px-4">
            <div class="grid grid-cols-5 gap-2">
              <div
                class="col-span-2 rounded-xl h-28"
                :style="{
                  backgroundImage: 'url(\'' + fotoPaket + '\')',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  backgroundSize: 'contain',
                }"></div>
              <div class="col-span-3">
                <div class="grid grid-cols-1 font-bold">
                  <span class="text-primary text-xl">{{ $props.dataTrx.namapaket }}</span>
                  <span class="text-black text-base">{{ $props.dataTrx.namatoko }}</span>
                  <span class="text-black text-sm">{{ $props.dataTrx.alamattoko }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="border-2 border-grey-1 my-2"></div>
        <div class="w-full mt-6">
          <div class="w-full">
            <div class="my-1 flex flex-col gap-2 font-semibold md:font-bold text-sm md:text-base">
              <div class="flex w-full">
                <div class="w-1/2">
                  <span class="">Nama</span>
                </div>
                <div class="w-1/2 flex">
                  <span class="mr-1">:</span>
                  <span class="">{{ $store.state.user.nama }}</span>
                </div>
              </div>
              <div class="flex w-full">
                <div class="w-1/2">
                  <span class="">Booking Date</span>
                </div>
                <div class="w-1/2 flex">
                  <span class="mr-1">:</span>
                  <span class="">
                    {{ $moment($props.dataTrx.tgl).format("dddd, DD MMMM YYYY") }}
                  </span>
                </div>
              </div>
              <div class="flex w-full" v-if="tipePaket == 'RENTAL'">
                <div class="w-1/2">
                  <span class="">Start Time / Duration</span>
                </div>
                <div class="w-1/2 flex">
                  <span class="mr-1">:</span>
                  <span class="">
                    {{ $props.dataTrx.jam_awal | reformatHour }} / {{ $props.dataTrx.durasi }} Hour(s)
                  </span>
                </div>
              </div>
              <div class="flex w-full" v-else-if="tipePaket == 'SAFARI'">
                <div class="w-1/2">
                  <span class="">Booking Time</span>
                </div>
                <div class="w-1/2 flex">
                  <span class="mr-1">:</span>
                  <span class="">
                    {{ $props.dataTrx.jam_awal | reformatHour }} -
                    {{ $props.dataTrx.jam_akhir | reformatHour }}
                  </span>
                </div>
              </div>
              <div class="flex w-full">
                <div class="w-1/2">
                  <span class="">Location</span>
                </div>
                <div class="w-1/2 flex">
                  <span class="mr-1">:</span>
                  <span class="">
                    {{ $props.dataTrx.dermaga }}
                  </span>
                </div>
              </div>
              <div class="flex w-full">
                <div class="w-1/2">
                  <span class="">Booked Unit</span>
                </div>
                <div class="w-1/2 flex">
                  <span class="mr-1">:</span>
                  <span class="">
                    {{ $props.dataTrx.unit }}
                  </span>
                </div>
              </div>
              <div class="flex w-full">
                <div class="w-1/2">
                  <span class="">Payment Menthod</span>
                </div>
                <div class="w-1/2 flex">
                  <span class="mr-1">:</span>
                  <span class="">
                    {{ paymentMode }}
                  </span>
                </div>
              </div>
            </div>
            <!-- <div class="my-1 flex flex-row justify-between">
              <div class="flex flex-col gap-2">
                <span class="font-bold text-black text-md">Nama</span>
                <span class="font-bold text-black text-md">Booking Date</span>
                <span class="font-bold text-black text-md" v-if="tipePaket == 'RENTAL'">Start Time / Duration</span>
                <span class="font-bold text-black text-md" v-else-if="tipePaket == 'SAFARI'">Booking Time</span>
                <span class="font-bold text-black text-md">Location</span>
                <span class="font-bold text-black text-md">Booked Unit</span>
                <span class="font-bold text-black text-md">Payment Method</span>
              </div>
              <div class="flex flex-col gap-2">
                <span class="font-bold text-black text-md">
                  : {{ $store.state.user.nama }}</span>
                <span class="font-bold text-black text-md">
                  :
                  {{
                      $moment($props.dataTrx.tgl).format("dddd, DD MMMM YYYY")
                  }}</span>
                <span class="font-bold text-black text-md" v-if="tipePaket == 'RENTAL'">
                  : {{ $props.dataTrx.jam_awal | reformatHour }} /
                  {{ $props.dataTrx.durasi }} Hour(s)</span>
                <span class="font-bold text-black text-md" v-else-if="tipePaket == 'SAFARI'">
                  : {{ $props.dataTrx.jam_awal | reformatHour }} -
                  {{ $props.dataTrx.jam_akhir | reformatHour }}</span>
                <span class="font-bold text-black text-md">
                  : {{ $props.dataTrx.dermaga }}</span>
                <span class="font-bold text-black text-md">
                  : {{ $props.dataTrx.unit }}</span>
                <span class="font-bold text-black text-md">
                  : {{ paymentMode }}</span>
              </div>
            </div> -->
          </div>
        </div>
        <div class="w-full mt-6">
          <div class="border border-primary rounded-2xl">
            <div class="flex flex-col gap-2 p-4 font-semibold md:font-bold text-sm md:text-base">
              <div
                class="flex flex-row justify-between"
                v-for="(psn, idxpsn) in $props.dataTrx.daftarpesanan"
                :key="idxpsn">
                <span class="w-4/5 text-black"
                  >{{ psn.namapaket }} {{ psn.qtyunit }} x {{ psn.harga | toCurrency }}/hour</span
                >
                <span class="w-4/5 text-black text-right"> {{ psn.jumlah | toCurrency }}</span>
              </div>
              <div class="flex flex-row justify-between" v-if="$props.dataTrx.diskon > 0">
                <span class="w-4/5 text-black">Voucher</span>
                <span class="w-4/5 text-black text-right">{{ $props.dataTrx.diskon | toCurrency }}</span>
              </div>
              <div class="flex flex-row justify-between">
                <span class="w-4/5 text-black" v-if="!isCreditCard && !isTwPay">Unique Code</span>
                <span class="w-4/5 text-black" v-if="isCreditCard && !isTwPay">Credit Card admin fee (3.5%)</span>
                <span class="w-4/5 text-black text-right" v-if="!isTwPay">{{ $props.dataTrx.unik | toCurrency }}</span>
              </div>
            </div>
            <div class="font-bold px-4 py-2 bg-primary text-white rounded-br-2xl rounded-bl-2xl">
              <div class="flex flex-row justify-between">
                <span class="">Total Payment</span>
                <span class="">{{ jumlahakhir | toCurrency }}</span>
              </div>
            </div>
          </div>

          <div class="flex flex-col gap-2 font-bold m-4">
            <p class="text-sm text-black m-auto">Please complete your booking payment in:</p>
            <div class="rounded-xl text-primary text-lg border border-primary py-1.5 px-20 m-auto">
              <countdown :time="timeExp" :transform="transform" v-if="timeExp > 0">
                <template slot-scope="props">{{ props.hours }} : {{ props.minutes }} : {{ props.seconds }}</template>
              </countdown>
              <span v-else>Expired</span>
            </div>
            <div class="flex flex-col justify-center md:flex-row gap-2 md:gap-4 mt-2">
              <a
                :href="dataTrx.redirect_url"
                target="_blank"
                class="rounded-xl text-base text-white bg-primary border text-center border-primary py-1 px-10 cursor-pointer"
                v-if="timeExp > 0 && isDana">
                Continue Payment
              </a>
              <span class="grid items-center justify-center text-sm text-black" v-if="timeExp > 0 && (isBankTransfer || isDana || isTwPay)">Or</span>
              <div
                class="rounded-xl text-base text-white bg-primary border text-center border-primary py-1 px-10 cursor-pointer"
                v-if="timeExp > 0 && (isBankTransfer || isDana || isTwPay)"
                @click="payTwpay">
                Pay using Balance
              </div>
            </div>
            <div
              class="rounded-xl text-base text-center bg-yellow border border-yellow py-1 px-10 md:px-20 mx-auto mt-3 cursor-pointer"
              @click="checkStatusTrx">
              Check Transaction Status
            </div>
          </div>
        </div>
      </div>
      <div v-if="isBankTransfer && !statusMerchant">
        <div class="text-center text-gray-500 font-medium mt-4">
          {{ banks[selectedBank].name }} virtual account number
        </div>
        <div class="flex justify-center items-start mt-1">
          <div class="text-2xl text-primary font-bold">
            {{ dataTrx.virtual_account }}
          </div>
          <button
            @click="copyToClipboard"
            class="border border-primary text-primary text-sm font-medium hover:bg-gray-100 rounded-full ml-4 px-2 py-1">
            <font-awesome-icon icon="fa-solid fa-copy" />
            Copy
          </button>
        </div>
        <div class="font-bold mt-8">Follow the payment instructions below :</div>
        <div class="border-gray-200 rounded-2xl shadow-sidebar mt-2">
          <div
            class="flex justify-between text-white items-center bg-primary hover:bg-primary-darker rounded-full px-4 py-1 cursor-pointer"
            @click="toggleAtmAccordion">
            <div class="font-semibold">ATM {{ banks[selectedBank].name }}</div>
            <font-awesome-icon v-if="showAccordion.atm" icon="fa-solid fa-caret-up" size="lg" />
            <font-awesome-icon v-else icon="fa-solid fa-caret-down" size="lg" />
          </div>
          <div class="py-4 px-8" v-if="showAccordion.atm">
            <ol class="text-primary text-sm font-medium list-decimal">
              <li v-for="item in banks[selectedBank].atm" :key="item">
                {{ item }}
              </li>
            </ol>
          </div>
        </div>
        <div class="border-gray-200 rounded-2xl shadow-sidebar mt-4">
          <div
            class="flex justify-between text-white items-center bg-primary hover:bg-primary-darker rounded-full px-4 py-1 cursor-pointer"
            @click="toggleMobileAccordion">
            <div class="font-semibold">Mobile Banking {{ banks[selectedBank].name }}</div>
            <font-awesome-icon v-if="showAccordion.mobile" icon="fa-solid fa-caret-up" size="lg" />
            <font-awesome-icon v-else icon="fa-solid fa-caret-down" size="lg" />
          </div>
          <div class="py-4 px-8" v-if="showAccordion.mobile">
            <ol class="text-primary text-sm font-medium list-decimal">
              <li v-for="item in banks[selectedBank].mobile" :key="item">
                {{ item }}
              </li>
            </ol>
          </div>
        </div>
        <div class="border-gray-200 rounded-2xl shadow-sidebar mt-4">
          <div
            class="flex justify-between text-white items-center bg-primary hover:bg-primary-darker rounded-full px-4 py-1 cursor-pointer"
            @click="toggleOtherAccordion">
            <div class="font-semibold">Other Bank</div>
            <font-awesome-icon v-if="showAccordion.other" icon="fa-solid fa-caret-up" size="lg" />
            <font-awesome-icon v-else icon="fa-solid fa-caret-down" size="lg" />
          </div>
          <div class="text-primary py-4 px-8" v-if="showAccordion.other">
            <div class="font-bold">Top up via ATM</div>
            <ol class="font-medium text-sm list-decimal">
              <li>Insert your ATM Card and enter your PIN</li>
              <li>Choose Other Transaction and select Transfer</li>
              <li>
                Choose Other Bank and select {{ banks[selectedBank].name }} or enter {{ banks[selectedBank].name }} bank
                code ({{ banks[selectedBank].bankCode }})
              </li>
              <li>
                Enter your Account number (example:
                {{ banks[selectedBank].vaShort }}xxxxxxxxxxx)
              </li>
              <li>Enter amount of the invoice</li>
              <li>Follow the instructions to complete the transaction</li>
            </ol>
            <div class="font-bold mt-4">Top up via m-Banking</div>
            <ol class="font-medium text-sm list-decimal">
              <li>Login to m-Banking apps</li>
              <li>
                Choose Transfer > Other Bank and select
                {{ banks[selectedBank].name }} or enter {{ banks[selectedBank].name }} bank Code ({{
                  banks[selectedBank].bankCode
                }})
              </li>
              <li>
                Enter your Balance Account number (example:
                {{ banks[selectedBank].vaShort }}xxxxxxxxxxx)
              </li>
              <li>Enter amount of the invoice</li>
              <li>Follow the instructions to complete the transaction</li>
            </ol>
          </div>
        </div>
      </div>
      <div v-else-if="statusMerchant">
        <div class="text-center text-gray-500 font-medium mt-4">
          {{ $store.state.currency.nama_bank.toUpperCase() }} account
        </div>
        <div class="flex justify-center items-start mt-1">
          <div class="text-2xl text-primary font-bold">
            {{ $store.state.currency.nama_rekening }}
          </div>
        </div>
        <div class="flex justify-center items-start mt-1">
          <div class="text-2xl text-primary font-bold">
            {{ $store.state.currency.no_rekening }}
          </div>
          <button
            @click="copyToClipboard"
            class="border border-primary text-primary text-sm font-medium hover:bg-gray-100 rounded-full ml-4 px-2 py-1">
            <font-awesome-icon icon="fa-solid fa-copy" />
            Copy
          </button>
        </div>
        <div class="flex justify-center items-start mt-1">
          <div class="text-lg text-primary font-bold">
            <a :href="`http://wa.me/${$store.state.currency.no_konfirm}`" target="_blank"
              >WhatsApp to confirm payment:</a
            >
          </div>
        </div>
        <div class="flex justify-center items-start mt-1">
          <div class="text-2xl text-primary font-bold">
            <a :href="`http://wa.me/${$store.state.currency.no_konfirm}`" target="_blank">{{
              $store.state.currency.no_konfirm
            }}</a>
          </div>
        </div>
        <div class="font-bold mt-8">Follow the payment instructions below :</div>
        <div class="border-gray-200 rounded-2xl shadow-sidebar mt-2">
          <div
            class="flex justify-between text-white items-center bg-primary hover:bg-primary-darker rounded-full px-4 py-1 cursor-pointer"
            @click="toggleAtmAccordion">
            <div class="font-semibold">ATM {{ $store.state.currency.nama_bank.toUpperCase() }}</div>
            <font-awesome-icon v-if="showAccordion.atm" icon="fa-solid fa-caret-up" size="lg" />
            <font-awesome-icon v-else icon="fa-solid fa-caret-down" size="lg" />
          </div>
          <div class="py-4 px-8" v-if="showAccordion.atm">
            <ol class="text-primary text-sm font-medium list-decimal">
              <li v-for="item in banks[3].atm" :key="item">{{ item }}</li>
            </ol>
          </div>
        </div>
        <div class="border-gray-200 rounded-2xl shadow-sidebar mt-4">
          <div
            class="flex justify-between text-white items-center bg-primary hover:bg-primary-darker rounded-full px-4 py-1 cursor-pointer"
            @click="toggleMobileAccordion">
            <div class="font-semibold">Mobile Banking {{ $store.state.currency.nama_bank.toUpperCase() }}</div>
            <font-awesome-icon v-if="showAccordion.mobile" icon="fa-solid fa-caret-up" size="lg" />
            <font-awesome-icon v-else icon="fa-solid fa-caret-down" size="lg" />
          </div>
          <div class="py-4 px-8" v-if="showAccordion.mobile">
            <ol class="text-primary text-sm font-medium list-decimal">
              <li v-for="item in banks[selectedBank].mobile" :key="item">
                {{ item }}
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-yellow text-black text-xs font-bold p-4 rounded-xl mt-4">
      <span>
        <font-awesome-icon icon="fas fa-info-circle" class="text-primary text-lg" />
        Immediately finish the payment for your booking according to the listed amount along with the unique code. Your
        booking will be cancelled by the system if the trip until availability runs out before your payment is finished.
      </span>
    </div>

    <modal-pin ref="modalPin"></modal-pin>
  </div>
</template>

<script>
export default {
  name: "TransactionUnpaid",
  components: {
    ModalPin: () => import("@/components/layout/ModalPin.vue"),
  },
  data() {
    return {
      statusMerchant: false,
      iconUnit: require("@/assets/icons/jetski_icon.svg"),
      banks: [
        {
          name: "BCA",
          image: require("@/assets/bca-icon.png"),
          virtual_account: this.$store.state.saldo.BCA,
          vaShort: "14082",
          bankCode: "014",
          atm: [
            "Insert your ATM card and enter your PIN",
            "Select Transfer and click BCA Virtual Account",
            "Enter your Virtual Account number (example: 14082xxxxxxxxxxx)",
            "Enter amount of the invoice",
            "Follow the next instructions to complete top-up",
          ],
          mobile: [
            "Login to m-BCA",
            "Select M-Transfer > Transfer BCA Virtual Account ",
            "Enter your Virtual Account number (example: 14082xxxxxxxxxxx)",
            "Enter amount of the invoice",
            "Enter your m-BCA PIN",
            "Follow the next instructions to complete top-up",
          ],
        },
        {
          name: "BRI",
          image: require("@/assets/bri-icon.png"),
          virtual_account: this.$store.state.saldo.BRI,
          vaShort: "13009",
          bankCode: "002",
          atm: [
            "Insert your ATM Card  and enter your PIN",
            "Select Transaksi Lainnya > Pembayaran > Menu Lainnya",
            "Select BRIVA",
            "Enter your Balance Account number (example: 13009xxxxxxxxxxx)",
            "Enter amount of the invoice",
            "Follow the instructions to complete the transaction",
          ],
          mobile: [
            "Login to Brimo",
            "Select BRIVA",
            "Enter your Balance Account number (example: 13009xxxxxxxxxxx)",
            "Enter amount of the invoice",
            "Follow the instructions to complete the transaction",
          ],
        },
        {
          name: "Permata",
          image: require("@/assets/permata-icon.png"),
          virtual_account: this.$store.state.saldo.PERMATA,
          vaShort: "8687",
          bankCode: "013",
          atm: [
            "Insert your ATM Card  and enter your PIN",
            "Select Transfer > Transfer ke Rekening PermataBank or select Pembayaran > Virtual Account",
            "Enter your Balance Account number (example: 8687xxxxxxxxxxxx)",
            "Enter amount of the invoice",
            "Follow the instructions to complete the transaction",
          ],
          mobile: [
            "Login to PermataMobile X",
            "Select Bayar Tagihan > Akun Virtual",
            "Enter your Balance Account number (example: 8687xxxxxxxxxxxx)",
            "Enter amount of the invoice",
            "Follow the instructions to complete the transaction",
          ],
        },
        {
          name: "MANUAL",
          image: require("@/assets/bca-icon.png"),
          virtual_account: this.$store.state.saldo.BCA,
          vaShort: "14082",
          bankCode: "014",
          atm: [
            "Insert your ATM card and enter your PIN",
            "Select Transfer",
            "Enter your Account number (example: 14082xxxxxxxxxxx)",
            "Enter amount of the invoice",
            "Show evidence of transfer to admin",
            "Wait for the admin to confirm your payment",
          ],
          mobile: [
            "Login to mBanking Apps",
            "Select Transfer",
            "Enter your Account number (example: 14082xxxxxxxxxxx)",
            "Enter amount of the invoice",
            "Enter your mBanking PIN",
            "Show evidence of transfer to admin",
            "Wait for the admin to confirm your payment",
          ],
        },
      ],
      showAccordion: { atm: true, mobile: false, other: false },
      selectedBank: 0,
    };
  },
  props: ["dataTrx"],
  computed: {
    tipePaket() {
      return this.$props.dataTrx.tipe;
    },
    timeExp() {
      const now = new Date();
      const newYear = this.$moment(this.$props.dataTrx.waktu_exp);
      return newYear - now;
    },
    fotoPaket() {
      var txt = this.$props.dataTrx.foto;
      // if (this.$props.dataTrx.tipe == "RENTAL") {
      //   txt =
      //     this.$props.dataTrx.daftarpesanan[0]?.foto ??
      //     "https://tripwe.id/ic/jetski-android-new/upload_logo/tw_sq.png";
      // }
      return txt;
    },
    getBankName() {
      var txt = "";
      switch (this.$props.dataTrx.tipe_reff) {
        case "PMT":
          txt = "Permata";
          break;
        case "BRI":
          txt = "BRI";
          break;
        case "BCA":
          txt = "BCA";
          break;
        case "CC":
          txt = "Credit Card";
          break;
        case "TWPAY":
          txt = "Balance";
          break;

        default:
          break;
      }
      return txt;
    },
    isBankTransfer() {
      var txt = "";
      if (
        this.$props.dataTrx.tipe_reff == "BCA" ||
        this.$props.dataTrx.tipe_reff == "BRI" ||
        this.$props.dataTrx.tipe_reff == "PMT" ||
        ((this.$props.dataTrx.tipe_reff == null || this.$props.dataTrx.tipe_reff == "") &&
          this.$props.dataTrx.reff.slice(0, 2) != "CC" &&
          this.$props.dataTrx.reff.slice(0, 3) != "C2C")
      ) {
        return true;
      }
      return false;
    },
    isDana() {
      if (this.$props.dataTrx.tipe_reff == "DANA") {
        return true;
      }
      return false;
    },
    isTwPay() {
      var txt = "";
      if (
        this.$props.dataTrx.tipe_reff == "TWPAY" ||
        ((this.$props.dataTrx.tipe_reff == null || this.$props.dataTrx.tipe_reff == "") &&
          this.$props.dataTrx.reff.slice(0, 3) == "C2C")
      ) {
        return true;
      }
      return false;
    },
    isCreditCard() {
      if (
        this.$props.dataTrx.tipe_reff == "CC" ||
        ((this.$props.dataTrx.tipe_reff == null || this.$props.dataTrx.tipe_reff == "") &&
          this.$props.dataTrx.reff.slice(0, 2) == "CC")
      ) {
        return true;
      }
      return false;
    },
    paymentMode() {
      var str = "Bank Transfer";
      if (this.isBankTransfer) {
        str = "Bank Transfer";
      } else if (this.isTwPay) {
        str = "Balance";
      } else if (this.isCreditCard) {
        str = "Credit Card";
      }
      return str;
    },
    getBankVA() {
      var txt = "";
      switch (this.$props.dataTrx.tipe_reff) {
        case "PMT":
          txt = this.$store.state.saldo.PERMATA;
          break;
        case "BRI":
          txt = this.$store.state.saldo.BRI;
          break;
        case "BCA":
          txt = this.$store.state.saldo.BCA;
          break;

        default:
          break;
      }
      return txt;
    },
    feeCC() {
      if (this.isCreditCard) {
        return (this.$props.dataTrx.jml_dskn * 3.5) / 100;
      }
      return 0;
    },
    jumlahakhir() {
      if (this.isTwPay) {
        return this.$props.dataTrx.jml_dskn;
      } else if (!this.isTwPay) {
        return parseFloat(this.$props.dataTrx.jml_dskn) + parseFloat(this.$props.dataTrx.unik);
      } else {
        return this.$props.dataTrx.jumlahakhir;
      }
    },
  },
  created() {
    switch (this.$props.dataTrx.tipe_reff) {
      case "PMT":
        this.selectedBank = 2;
        break;
      case "BRI":
        this.selectedBank = 1;
        break;
      case "BCA":
        this.selectedBank = 0;
        break;
      default:
        this.selectedBank = 0;
        break;
    }
    this.checkKhusus();
  },
  watch: {
    dataTrx: function (newVal, oldVal) {
      // watch it
      switch (this.$props.dataTrx.tipe_reff) {
        case "PMT":
          this.selectedBank = 2;
          break;
        case "BRI":
          this.selectedBank = 1;
          break;
        case "BCA":
          this.selectedBank = 0;
          break;
        default:
          this.selectedBank = 0;
          break;
      }
    },
  },
  methods: {
    copyToClipboard() {
      navigator.clipboard.writeText(this.dataTrx?.virtual_account);
      this.$toasted.global.success("Virtual account number has been copied to clipboard");
    },
    toggleAtmAccordion() {
      this.showAccordion.atm = !this.showAccordion.atm;
      this.showAccordion.mobile = false;
      this.showAccordion.other = false;
    },
    toggleMobileAccordion() {
      this.showAccordion.atm = false;
      this.showAccordion.mobile = !this.showAccordion.mobile;
      this.showAccordion.other = false;
    },
    toggleOtherAccordion() {
      this.showAccordion.atm = false;
      this.showAccordion.mobile = false;
      this.showAccordion.other = !this.showAccordion.other;
    },
    transform(props) {
      Object.entries(props).forEach(([key, value]) => {
        // Adds leading zero
        const digits = value < 10 ? `0${value}` : value;

        // uses singular form when the value is less than 2
        const word = value < 2 ? key.replace(/s$/, "") : key;

        props[key] = `${digits}`;
      });

      return props;
    },
    async checkStatusTrx() {
      this.$store.commit("setOverlayLoading", true);
      const res = await this.$http.post(`${this.$apiTripweWeb}/transaction/order/search`, {
        search: this.$props.dataTrx.nobukti,
      });
      if (res.data.status == 200) {
        var data = res.data.data;
        this.$emit("cekStatus", data);
      }
      this.$store.commit("setOverlayLoading", false);
    },
    async validasiSaldo() {
      this.$store.commit("setOverlayLoading", true);
      var resSaldo = await this.$http.get(`${this.$apiTripweWeb}/tripwepay/balance`);

      this.$store.commit("setOverlayLoading", false);
      this.$store.commit("setSaldo", resSaldo.data.response);
      if (this.$store.state.saldo.SaldoAktif < this.$props.dataTrx.jml_dskn) {
        this.$toasted.global.error("Balance amount less than total payment");
        // this.$router.push({
        //   name: "Topup",
        // });
        return false;
      }
      return true;
    },
    async payTwpay() {
      var valid = await this.validasiSaldo();
      if (!valid) {
        return;
      }
      const confirmed = await this.$refs.modalPin.open({
        button: "OK",
      });
      if (confirmed) {
        this.payC2Chold();
      }
    },
    randomStr(min, max) {
      return Math.floor(Math.random() * (max - min + 1) + min);
    },
    async payC2Chold() {
      this.$store.commit("setOverlayLoading", true);
      var now = this.$moment();
      let token = "TWIP" + now.format("YYYYMMDDHHmmss") + this.randomStr(1, 9999);
      var datapost = {
        customer_name: this.$store.state.user?.nama ?? "",
        payment_amount: this.$props?.dataTrx?.jml_dskn ?? 0,
        virtual_account: this.$props?.dataTrx?.vatoko, // VA Tenant
        trx_id: this.$props.dataTrx.nobukti ?? "",
        description: "Payment for Invoice no.",
        token: token,
        app: "TRIPWEDEV",
        client_id: "WEBAPPS",
      };

      try {
        var res = await this.$http.post(`${this.$apiTripweWebV2}/transactions/pay`, {
          nobukti: this.$props.dataTrx.nobukti ?? "",
        });
        this.$store.commit("setOverlayLoading", false);
        if (res.data.status != 200) {
          throw res.data.message;
        }

        var resSaldo = await this.$http.get(`${this.$apiTripweWeb}/tripwepay/balance`);
        this.$store.commit("setSaldo", resSaldo.data.response);
        var trip = "";
        if (this.$props.dataTrx.tipe == "RENTAL") {
          trip = "rental";
        } else if (this.$props.dataTrx.tipe == "SAFARI") {
          trip = "safari";
        } else if (this.$props.dataTrx.tipe == "OFFROAD") {
          trip = "offroad";
        } else if (this.$props.dataTrx.tipe == "ONROAD") {
          trip = "onroad";
        } else if (this.$props.dataTrx.tipe == "SKY") {
          trip = "sky";
        }

        this.$router.replace({
          name: "Invoice",
          params: {
            slug: "process",
            trip: trip,
          },
        });
      } catch (error) {
        this.$toasted.global.error(error);
      }
    },

    async checkKhusus() {
      this.$store.commit("setOverlayLoading", true);
      try {
        const res = await this.$http.post(`${this.$apiTripweWeb}/config/checkKhusus`, {
          idtoko: this.dataTrx.toko,
        });
        if (res.data.status == "200") {
          var data = res.data;
          this.statusMerchant = data.response;
        }
      } catch (error) {
        this.$toasted.global.error(error);
      } finally {
        this.$store.commit("setOverlayLoading", false);
      }
    },
  },
};
</script>
