<template>
  <div class="w-full">
    <div class="flex flex-col md:flex-row mb-4">
      <div class="w-full md:w-9/12">
        <div class="font-extrabold text-black text-lg my-4">
          Booking No. {{ $props.dataTrx.nobukti }}
        </div>
        <div class="w-full md:px-4">
          <div class="flex flex-row gap-4">
            <div
              class="w-1/5 rounded-xl h-28"
              :style="{
                backgroundImage: 'url(\'' + fotoPaket + '\')',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'contain',
              }"
            ></div>
            <div class="w-3/5">
              <div class="grid grid-cols-1 font-bold">
                <span class="text-primary text-xl">{{
                  $props.dataTrx.namapaket
                }}</span>
                <span class="text-black text-md">{{
                  $props.dataTrx.namatoko
                }}</span>
                <span class="text-black text-sm">{{
                  $props.dataTrx.alamattoko
                }}</span>
              </div>
            </div>
            <div class="w-1/5">
              <div
                class="grid grid-cols-1 content-start items-end text-primary h-full"
              >
                <span class="font-bold cursor-pointer" @click="redirectNewTab">
                  <font-awesome-icon
                    icon="fa-solid fa-location-dot"
                    class="mt-3"
                  />
                  Meeting Point
                </span>
              </div>
            </div>
          </div>
          <div class="border-2 border-grey-1 my-4"></div>
          <div class="md:grid md:grid-cols-2 gap-10">
            <div
              class="my-1 flex flex-col gap-2 font-semibold md:font-bold text-sm md:text-base"
            >
              <div class="flex w-full">
                <div class="w-1/2">
                  <span class="">Nama</span>
                </div>
                <div class="w-1/2 flex">
                  <span class="mr-1">:</span>
                  <span class="">{{ $store.state.user.nama }}</span>
                </div>
              </div>
              <div class="flex w-full">
                <div class="w-1/2">
                  <span class="">Starting Date</span>
                </div>
                <div class="w-1/2 flex">
                  <span class="mr-1">:</span>
                  <span class="">
                    {{
                      $moment($props.dataTrx.tgl).format("dddd, DD MMMM YYYY")
                    }}
                  </span>
                </div>
              </div>
              <div class="flex w-full">
                <div class="w-1/2">
                  <span class="">Starting Time</span>
                </div>
                <div class="w-1/2 flex">
                  <span class="mr-1">:</span>
                  <span class="">
                    {{ $props.dataTrx.jam_awal | reformatHour }}
                  </span>
                </div>
              </div>
              <div class="flex w-full">
                <div class="w-1/2">
                  <span class="">Trip Duration</span>
                </div>
                <div class="w-1/2 flex">
                  <span class="mr-1">:</span>
                  <span class="">
                    {{ $props.dataTrx.durasi }}
                  </span>
                </div>
              </div>
              <div class="flex w-full">
                <div class="w-1/2">
                  <span class="">Meeting Point</span>
                </div>
                <div class="w-1/2 flex">
                  <span class="mr-1">:</span>
                  <span class="">
                    {{ $props.dataTrx.dermaga }}
                  </span>
                </div>
              </div>
              <div class="flex w-full">
                <div class="w-1/2">
                  <span class="">Booked Unit</span>
                </div>
                <div class="w-1/2 flex">
                  <span class="mr-1">:</span>
                  <span class="">
                    {{ $props.dataTrx.unit }}
                  </span>
                </div>
              </div>
            </div>
            <div
              class="my-1 flex flex-col gap-2 font-semibold md:font-bold text-sm md:text-base"
            >
              <div class="flex w-full">
                <div class="w-1/2">
                  <span class="">Payment Date</span>
                </div>
                <div class="w-1/2 flex">
                  <span class="mr-1">:</span>
                  <span class="">
                    {{
                      $moment($props.dataTrx.waktu_bayar).format(
                        "dddd, DD MMMM YYYY"
                      )
                    }}
                  </span>
                </div>
              </div>
              <div class="flex w-full">
                <div class="w-1/2">
                  <span class="">Payment Time</span>
                </div>
                <div class="w-1/2 flex">
                  <span class="mr-1">:</span>
                  <span class="">
                    {{ $moment($props.dataTrx.waktu_bayar).format("HH:mm:ss") }}
                  </span>
                </div>
              </div>
              <div class="flex w-full">
                <div class="w-1/2">
                  <span class="">Payment Method</span>
                </div>
                <div class="w-1/2 flex">
                  <span class="mr-1">:</span>
                  <span class="">
                    {{ paymentMode }}
                  </span>
                </div>
              </div>
              <div class="flex w-full">
                <div class="w-1/2">
                  <span class="">Subtotal</span>
                </div>
                <div class="w-1/2 flex">
                  <span class="mr-1">:</span>
                  <span class="">
                    {{ $props.dataTrx.jumlah | toCurrency }}
                  </span>
                </div>
              </div>
              <div class="flex w-full">
                <div class="w-1/2">
                  <span class="">Total Payment</span>
                </div>
                <div class="w-1/2 flex">
                  <span class="mr-1">:</span>
                  <span class="">
                    <span
                      class="font-bold text-black text-md"
                    >
                      {{ jumlahakhir | toCurrency }}</span
                    >
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full mt-4 md:hidden">
            <div class="grid justify-items-center">
              <qrcode
                :value="$props.dataTrx.nobukti"
                :options="{ width: 240 }"
                tag="img"
                class="border border-gray-200 rounded-2xl shadow-lg"
              ></qrcode>
            </div>
            <div class="flex flex-col font-bold mt-4 gap-4">
              <span class="m-auto text-xs text-center"
                >Show the QR Code at the Meeting Point to start your trip</span
              >
            </div>
          </div>
          <div class="border border-primary rounded-2xl mt-4">
            <div
              class="flex flex-col gap-2 p-4 font-semibold md:font-bold text-sm md:text-base"
            >
              <div
                class="flex flex-row justify-between"
                v-for="(psn, idxpsn) in $props.dataTrx.daftarpesanan"
                :key="idxpsn"
              >
                <span class="text-black"
                  >{{ psn.namapaket }} {{ psn.qtyunit }}x
                  {{ psn.harga | toCurrency }}/hour</span
                >
                <span class="text-black">{{ psn.jumlah | toCurrency }}</span>
              </div>
              <div
                class="flex flex-row justify-between"
                v-if="$props.dataTrx.diskon > 0"
              >
                <span class="text-black">Voucher</span>
                <span class="text-black">{{
                  $props.dataTrx.diskon | toCurrency
                }}</span>
              </div>
              <div class="flex flex-row justify-between">
                <span class="text-black" v-if="!isCreditCard && !isTwPay"
                  >Unique Code</span
                >
                <span class="text-black" v-if="isCreditCard && !isTwPay"
                  >Credit Card admin fee (3.5%)</span
                >
                <span class="text-black text-right" v-if="!isTwPay">{{
                  $props.dataTrx.unik | toCurrency
                }}</span>
              </div>
            </div>
            <div
              class="px-4 py-2 bg-primary text-white rounded-br-2xl rounded-bl-2xl"
            >
              <div class="flex flex-row justify-between">
                <span class="">Total Payment</span>
                <span class="">{{
                  jumlahakhir | toCurrency
                }}</span>
              </div>
            </div>
          </div>
          <div
            v-if="isCreditCard || isBankTransfer"
            class="rounded-xl text-base text-white text-center bg-primary border border-primary py-1 px-20 m-auto mt-5 cursor-pointer"
            @click="checkStatusTrx"
          >
            Check Transaction Status
          </div>
          <div class="flex justify-center" v-if="dataTrx.status_waiver == '1'">
            <button
              type="button"
              class="bg-primary text-white font-extrabold py-2 px-4 mt-4 rounded-md font-xl"
              @click="handleFormWaiver"
            >
              Fill The Form
            </button>
          </div>
          <div class="flex justify-center">
            <button
              type="button"
              class="bg-red-500 text-white font-extrabold py-2 px-4 mt-4 rounded-md font-xl"
              @click="handleCancelation"
            >
              CANCEL BOOKING
            </button>
          </div>
        </div>
      </div>
      <div class="w-full md:w-3/12 hidden md:block">
        <div class="flex flex-row gap-2 justify-end px-2">
          <!-- <button type="button" class="text-lg"><font-awesome-icon icon="fa-solid fa-download" /></button> -->
          <button type="button" class="text-lg" @click="downloadInv()">
            <font-awesome-icon icon="fa-solid fa-print" class="text-lg" />
          </button>
        </div>
        <div class="grid justify-items-center">
          <qrcode
            :value="$props.dataTrx.nobukti"
            :options="{ width: 240 }"
            tag="img"
            class="border border-gray-200 rounded-2xl shadow-lg"
          ></qrcode>
        </div>
        <div class="flex flex-col font-bold mt-4 gap-4">
          <span class="m-auto">Booking No. {{ $props.dataTrx.nobukti }}</span>
          <span class="m-auto text-sm text-center"
            >Show the QR Code at the Meeting Point to start your trip</span
          >
        </div>
      </div>
    </div>
    <cancelation-policy-dialog
      ref="cancelationPolicyDialog"
    ></cancelation-policy-dialog>
    <confirmation-dialog ref="confirmationDialog"></confirmation-dialog>
  </div>
</template>

<script>
export default {
  name: "TransactionProcess",
  components: {
    CancelationPolicyDialog: () =>
      import("@/components/layout/CancelationPolicyDialog.vue"),
    ConfirmationDialog: () =>
      import("@/components/layout/ConfirmationDialog.vue"),
  },
  data() {
    return {
      iconUnit: require("@/assets/icons/jetski_icon.svg"),
    };
  },
  props: ["dataTrx"],
  computed: {
    timeExp() {
      const now = new Date();
      const newYear = this.$moment(this.$props.dataTrx.waktu_exp);
      return newYear - now;
    },
    fotoPaket() {
      var txt = this.$props.dataTrx.foto;
      // if (this.$props.dataTrx.tipe == "RENTAL") {
      //   txt =
      //     this.$props.dataTrx.daftarpesanan[0]?.foto ??
      //     "https://tripwe.id/ic/jetski-android-new/upload_logo/tw_sq.png";
      // }
      return txt;
    },
    isBankTransfer() {
      var txt = "";
      if (
        this.$props.dataTrx.tipe_reff == "BCA" ||
        this.$props.dataTrx.tipe_reff == "BRI" ||
        this.$props.dataTrx.tipe_reff == "PMT" ||
        ((this.$props.dataTrx.tipe_reff == null ||
          this.$props.dataTrx.tipe_reff == "") &&
          this.$props.dataTrx.reff.slice(0, 2) != "CC" &&
          this.$props.dataTrx.reff.slice(0, 3) != "C2C")
      ) {
        return true;
      }
      return false;
    },
    isTwPay() {
      var txt = "";
      if (
        this.$props.dataTrx.tipe_reff == "TWPAY" ||
        ((this.$props.dataTrx.tipe_reff == null ||
          this.$props.dataTrx.tipe_reff == "") &&
          this.$props.dataTrx.reff.slice(0, 3) == "C2C")
      ) {
        return true;
      }
      return false;
    },
    isCreditCard() {
      if (
        this.$props.dataTrx.tipe_reff == "CC" ||
        ((this.$props.dataTrx.tipe_reff == null ||
          this.$props.dataTrx.tipe_reff == "") &&
          this.$props.dataTrx.reff.slice(0, 2) == "CC")
      ) {
        return true;
      }
      return false;
    },
    paymentMode() {
      var str = "Bank Transfer";
      if (this.isBankTransfer) {
        str = "Bank Transfer";
      } else if (this.isTwPay) {
        str = "Balance";
      } else if (this.isCreditCard) {
        str = "Credit Card";
      }
      return str;
    },
    jumlahakhir() {
      if (this.isTwPay) {
        return this.$props.dataTrx.jml_dskn;
      } else if (!this.isTwPay) {
        return (
          parseFloat(this.$props.dataTrx.jml_dskn) +
          parseFloat(this.$props.dataTrx.unik)
        );
      } else {
        return this.$props.dataTrx.jumlahakhir;
      }
    },
  },
  methods: {
    redirectNewTab() {
      var url =
        "https://maps.google.com/?q=" +
        this.$props.dataTrx.latitude +
        "," +
        this.$props.dataTrx.longitude;
      window.open(url, "_blank").focus();
    },
    downloadInv() {
      this.$store.commit("setDetailInv", this.dataTrx);
      let routeData = this.$router.resolve({
        name: "InvoiceDownload",
        params: { data: this.$props.dataTrx },
      });
      window.open(routeData.href, "_blank");
    },
    randomStr(min, max) {
      return Math.floor(Math.random() * (max - min + 1) + min);
    },
    handleFormWaiver() {
      this.$router.push({
        name: "Waiver",
      });
      return this.$store.commit("setWaiverPackage", this.dataTrx);
    },
    async handleCancelation() {
      const alert = await this.$refs.cancelationPolicyDialog.open({
        title: "Cancelation Policy",
        button: "Close",
        confirmation: true,
      });
      if (alert) {
        const confirmed = await this.$refs.confirmationDialog.open({
          title: "Cancel Booking",
          message: "Are you sure want to cancel your trip ?",
          button: "Cancel Booking",
          buttonCancel: "Keep this Booking",
        });
        if (confirmed) {
          this.$store.commit("setOverlayLoading", true);
          try {
            var now = this.$moment();
            let token =
              "TWCA" + now.format("YYYYMMDDHHmmss") + this.randomStr(1, 9999);
            const res = await this.$http.post(
              `${this.$apiTripweWebV2}/transactions/cancel`,
              {
                nobukti: this.$props.dataTrx.nobukti,
                type: "CUSTOMER",
              }
            );
            if (res.data.status == "200") {
              this.$toasted.global.success("Successfully cancel booking");
              await this.checkStatusTrx();
            } else {
              throw res.data.message;
            }
          } catch (error) {
            this.$toasted.global.error(error);
          } finally {
            this.$store.commit("setOverlayLoading", false);
          }
        }
      }
    },
    async checkStatusTrx() {
      this.$store.commit("setOverlayLoading", true);
      const res = await this.$http.post(`${this.$apiTripweWeb}/transaction/order/search`, {
        search: this.$props.dataTrx.nobukti,
      });
      if (res.data.status == 200) {
        var data = res.data.data;
        this.$emit("cekStatus", data);
      }
      this.$store.commit("setOverlayLoading", false);
    },
  },
};
</script>
